
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Stipend-Payment-Status',
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {
        tranche: '' as any,
        start_date: '' as any,
        end_date: '' as any,
        payment_quarter: '' as any,
      },
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      quarter: [],
      tranches_info: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      publictotaltrainee: 0,
      publictotalamount: 0,
      privatetotaltrainee: 0,
      privatetotalamount: 0,
      publicPrivatetotaltrainee: 0,
      publicPrivatetotalamount: 0,
      empDistricts: [],
      publicData: [] as any,
      privateData: [] as any,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: '',
          key: 'logo',
          sortable: true,
        },
        {
          name: 'Short Name',
          key: 'short_name',
          sortable: true,
        },
        {
          name: 'Full Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'Address',
          key: 'address',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'mail',
          sortable: true,
        },
        {
          name: 'Website',
          key: 'website',
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          short_name: 'ABC',
          name: 'A.B.C Bangladesh-Turkish Technical School',
          address: 'Nazirhat Municipality ,Fatickchari ',
          phone: '0312552697 ',
          mail: 'abcbdtktschool@yahoo.com',
          website: 'www.doulatpurabchighschool.edu.bd ',
        },
        {
          id: 2,
          short_name: 'ACA',
          name: 'ACA Technical Institute ',
          address: 'Faterpur, Enayetnagar, Matlab Uttor, Chandpur ',
          phone: '0312510677  ',
          mail: '	aca.seip@gmail.com ',
          website: 'www.aca.org ',
        },
      ],
      publicPrivateData: [
        {
          sl: 1,
          name: 'public',
          no_of_trainee: 0,
          amount: 0,
        },
        {
          sl: 2,
          name: 'private',
          no_of_trainee: 0,
          amount: 0,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getQuarter();
    await this.getTranches();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getQuarter() {
      await ApiService.get('configurations/quarter/list')
        .then((response) => {
          this.quarter = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getData() {
      if (
        this.batch.tranche != false &&
        this.batch.start_date != false &&
        this.batch.end_date != false &&
        this.batch.payment_quarter != false
      ) {
        this.loading = true;
        ApiService.get(
          'stipend/payment-summary?tranche=' +
            this.batch.tranche +
            '&start_date=' +
            this.batch.start_date +
            '&end_date=' +
            this.batch.end_date +
            '&quarter=' +
            this.batch.payment_quarter
        )
          .then((response) => {
            this.publicData = response.data.data?.public;
            this.privateData = response.data.data?.private;
            for (let i = 0; i < this.privateData?.length; i++) {
              this.privatetotaltrainee += this.privateData[i]?.trainee_count;
              this.privatetotalamount += this.privateData[i]?.stipend_amount;
            }
            for (let i = 0; i < this.publicData?.length; i++) {
              this.publictotaltrainee += this.publicData[i]?.trainee_count;
              this.publictotalamount += this.publicData[i]?.stipend_amount;
            }
            if (this.publicData?.length > 0) {
              this.publicCount();
            }
            if (this.privateData?.length > 0) {
              this.privateCount();
            }
            this.publicPrivatetotaltrainee =
              this.publicPrivateData[0].no_of_trainee +
              this.publicPrivateData[1].no_of_trainee;
            this.publicPrivatetotalamount =
              this.publicPrivateData[0].amount +
              this.publicPrivateData[1].amount;
            this.showCourseNotice = true;
            console.log(response);
            this.loading = false;
          })
          .catch((response) => {
            this.loading = false;

            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please check all the required field',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    publicCount() {
      for (let i = 0; i < this.publicData.length; i++) {
        this.publicPrivateData[0].no_of_trainee +=
          this.publicData[i]?.trainee_count;
        this.publicPrivateData[0].amount += this.publicData[i]?.stipend_amount;
      }
    },
    privateCount() {
      for (let i = 0; i < this.privateData.length; i++) {
        this.publicPrivateData[1].no_of_trainee +=
          this.privateData[i]?.trainee_count;
        this.publicPrivateData[1].amount += this.privateData[i]?.stipend_amount;
      }
    },

    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
